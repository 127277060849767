
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import Link from 'next/link';

export default function Custom404() {
  const { t } = useTranslation('common');
  return (
    <div className="flex items-center flex-col py-20">
      <div className="relative">
        <Image width={320} height={240} src="/images/status_1.png" alt="notfound image" />
      </div>
      <h1 className="block text-8xl font-extralight py-4 text-primary">404</h1>
      <h1 className="container mx-auto text-2xl text-center">{t('404.notfound')}</h1>
      <Link href="/" className="mt-8 py-2 px-4 rounded-xl bg-bringmos-600 hover:bg-primary text-white">
        {t('404.backtohome')}
      </Link>
    </div>
  );
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  